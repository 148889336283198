import React from 'react';

// import PreorderForm from '@components/Forms/Preorder/PreorderForm';
// import Layout from '@components/Layouts/Layout';
// import PreorderPhoto from '@images/preorder/preorderPhoto.jpg';
// import SEO from '@components/Layouts/SEO';
// import styles from './preorder.module.scss';

import NotFoundPage from '../404';

function Preorder() {
  return (
    <NotFoundPage />
    // {/* <SEO title="Предзаказ" description="Универсальный подарок для коллег, друзей и близких" />
    // <div className={styles.container}>
    //   <div className={styles.preorderBlock}>
    //     <div className={styles.metadata}>
    //       <div className={styles.imgBlock}>
    //         <img src={PreorderPhoto} alt="preorderPhoto" />
    //       </div>
    //       <div className={styles.infoBlock}>
    //         <div className={styles.title}>Оформить предзаказ на Премиальную фотокнигу</div>
    //         <div className={styles.description}>
    //           <span>
    //             Оформите предзаказ на фотокнигу по специальной цене — 8 690 вместо 9 990 руб.
    //           </span>
    //           <span>Предложение действует до 12.11 включительно.</span>
    //           <span>Срок действия сертификата — 3 месяца.</span>
    //           <span>
    //             Собрать фотокнигу можно ориентировочно с 20 ноября. Мы напомним вам об этом!
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <PreorderForm name="Премиальную фотокнигу" price={8690} />
    // </div> */}
  );
}

export default Preorder;
