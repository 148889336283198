import React from 'react';
import { Link } from 'gatsby';
import Layout from '@components/Layouts/Layout';
import { EmptyLayout, Link as A, Paragraph as P } from '@periodica/ui-kit';
import { OneColumn } from '@components/Layouts/OneColumn';
import SEO from '@components/Layouts/SEO';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Страница не найдена" description="Страница не найдена" />
      <OneColumn>
        <EmptyLayout
          title="Такой страницы нет"
          titleSize="large"
          hasIcon={false}
          description={
            <P size="inherit">
              Но у нас есть другие страницы, например –&nbsp;
              <A asChild>
                <Link to="/">главная</Link>
              </A>
              !
            </P>
          }
        />
      </OneColumn>
    </Layout>
  );
}

export default NotFoundPage;
